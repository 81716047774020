
/*-- *STEPS --*/

.payment_wrap ol.progtrckr {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    margin-bottom: 30px;
    counter-reset: steps;
}
.payment_wrap ol.progtrckr >li {
    border:none;
    color: #3a3937;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    justify-content: center;
    line-height: 22px;
    padding-right: 100px;
    position: relative;
    width: calc(100% /5);
}

.payment_wrap ol.progtrckr >li em{ display:none}
.payment_wrap ol.progtrckr >li span {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 10px;
    padding:0;
    text-align: center;
    width: 100%;
}

.payment_wrap ol.progtrckr li:before {
    align-items: center;
    background-color: #fff;
    border: 2px solid #2ECC71;
    border-radius: 0px !important;
    bottom: 0;
    box-sizing: border-box;
    content: counter(steps);
    counter-increment: steps;
    display: flex;
    float: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
    justify-content: center;
    left: auto;
    line-height: 22px;
    width: 60px;
}
.payment_wrap ol.progtrckr li.progtrckr-done:before,.payment_wrap ol.progtrckr li.progtrckr-doing:before{
  background-color: #2ecc71;
  color: #fff;
  height: 60px;
  width: 60px;
}
.payment_wrap ol.progtrckr li.progtrckr-done:before {
    content: '✓';
}
.payment_wrap ol.progtrckr li.progtrckr-done:hover:before,.payment_wrap ol.progtrckr li.progtrckr-doing:hover:before{
  color:#FFFFFF;
}
.payment_wrap ol.progtrckr li::after {
    content: '';
    position: absolute;
    top: 33%;
    left: 85%;
    width: 56px;
    height: 6px;
    display: block;
}
.payment_wrap ol.progtrckr li:last-child::after {display:none}
.payment_wrap ol.progtrckr li.progtrckr-doing {
    color: inherit;
    border: none;
}
@media screen and (max-width: 1440px){
  .payment_wrap ol.progtrckr >li {
      align-items: flex-start;
      padding: 30px;
  }
  .payment_wrap .footer-buttons {
      padding: 30px;
  }
}
@media screen and (max-width: 768px){
  .recipient_select_wrap {
    flex-wrap: wrap;
    justify-content: center;
  }  
  main .payment_wrap ol.progtrckr >li {
      align-items: flex-start;
      padding: .5em;
  }
  main .payment_wrap .footer-buttons {
      padding: .5em;
  }
  main .payment_wrap ol.progtrckr li::after {
    display: none;
  }
  main .payment_wrap .footer-buttons {
      padding: 2em;
  }
  main .payment_wrap .footer-buttons .btn{
    margin: 0 0.5em;
    padding: 0.5em;
    width: auto;
  }
}


.payment_wrap .footer-buttons {
    display: flex;
    padding: 40px 135px 160px;
    background-color: transparent !important;
    justify-content: space-between;
}

.payment_wrap .footer-buttons .btn.btn-prev.btn-primary {
    background-color: #FFFFFF;
    border: 2px solid #FA8819;
    border-radius: 0px;
    box-sizing: border-box;
    color:#3a3937;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins;
    font-weight: 100;
    height: 40px;
    line-height: normal;
    margin-left: auto;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 160px;
}

.payment_wrap .footer-buttons .btn.btn-next.btn-primary.btn-lg.pull-left{
    background-color: transparent;
    border-color: #CCC;
    border-radius: 0px;
    border-width: 2px;
    cursor: pointer;
    color: #000;
    font-family: Poppins;
    transition: all .25s ease;
    text-transform: uppercase;
    padding: 0;
    width: 160px;
}
.payment_wrap .footer-buttons .btn.btn-prev.btn-primary:hover,.payment_wrap .footer-buttons .btn.btn-next.btn-primary.btn-lg.pull-left:hover {  color:#FA8819;}

.payment_wrap button.edit_btn {
    border: 0px;
    background: none;
}



/* STEP 1 */
.promocode_wrap input {
    color: #FFF;
    margin-bottom: 0 !important;
    padding: 18.5px 14px;
}
.promocode_wrap .MuiOutlinedInput-notchedOutline{
  border-width: 0px !important;
  margin: 0;
  padding: 0 !important;
  position: relative;
  top: 0;
  width: 100%;
}
.promocode_wrap .MuiOutlinedInput-adornedStart{
  display: block;
}

.promocode_wrap .MuiIcon-root {
    font-size: 3rem;
}
.promocode_wrap .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: none;
  position: relative;
}


/* STEP 3*/

.choose_payment_metod_wrap label.control.control-radio[disabled] {
    background-color: #f6f6f6;
}
