
form input.form-control, .form textarea.form-control, form input.form-control, form textarea.form-control, .form select.form-control,form select.form-control {
  border: 1px solid #BBB;
  border-radius: 0px;
  box-sizing: border-box;
  height: 44px;
  margin-top: 6px;
  margin-bottom: 10px;
  padding: 0 20px;
  width: 100%;
}
/*--------------------*/



form.frmRegistro {
    width: 100% !important;
    display: block;
}

.panel.panel-danger.errors {
    display: none;
}

fieldset{
  border:none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
}
fieldset#root{
  background-color: #fff;
  padding: 30px 0 0;

}
fieldset:not(#root){
  margin: 0 0 20px 0;
  padding: 20px 50px 20px;
  border-bottom: 10px solid #f2f2f2;
}
fieldset > legend, .field-object >label{ display:none }
fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(odd){
  padding: 20px 0px 0px 50px;
  margin:0;
}
fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(even){
  padding: 20px 50px 0px 0px;
  margin:0;
}
fieldset#root > .form-group.field.full_row, fieldset#root > .form-group.field-boolean{
  padding: 20px 50px;
  width: 100%;
}

.form-group.field.field-object {
    width: 100%;
    position: relative;
}
.form-group.field.field-object p.help-block {
    position: absolute;
    bottom: 40px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    left:50px;
}
.form-group.field.field-object p.help-block:before{
    content:"!";
    color: #FA8819;
    font-weight: bold;
    width: 20px;
    margin-right: 5px;
}

.form-group .checkbox input[type=checkbox]{
  width: 20px;
  height: 20px;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.form-group .checkbox input[type=checkbox]:checked {
  background: #FA8819;
}
.form-group .checkbox label{ line-height: 32px; }



@media screen and (max-width: 768px){
  fieldset#root {
    padding: 1em 0 0 !important;
  }


  fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(odd),
  fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(even),
  fieldset#root > .form-group.field.full_row, fieldset#root > .form-group.field-boolean{
    padding: 0px 20px 20px;
    margin: 0;
  }
  fieldset:not(#root){ padding: 0px 20px 20px; }
  fieldset:not(#root) .form-group.field.half_row{
    padding: 20px 0 0px;
  }

  .form-group.field.field-object p.help-block{
    font-size: 12px !important;
    left:20px !important;
    margin-bottom: 20px;
    bottom: 20px !important;
  }

}

form.frmDatosPersonales{
  padding-bottom: 50px !important;
}
form.frmDatosPersonales fieldset:not(#root){
  border:none;
}
form.frmDatosPersonales button[type=submit]{
  margin: 0px 50px;
}

/* beneficiario */
form.frmBeneficiario .form-group {
    margin-bottom: 0px;
}
form.frmBeneficiario label[for=root_identificacion]{ display:none;}
form.frmBeneficiario fieldset#root_identificacion{
  padding: 0;border: none;
}
form.frmBeneficiario .form-group.field-submit.full_row{
  padding: 20px 50px;
  text-align: center;
}
form.frmCuentaBeneficiario {background-color: #FFFFFF;}
form.frmCuentaBeneficiario fieldset#root {
    padding: 0 20px;
    justify-content: center;
}
form.frmCuentaBeneficiario fieldset:not(#root){ border:none;     padding: 0 30px;}


/* beneficiario  modal */
.modal-body .form-group {
    margin-bottom: 0px;
}
.modal-body fieldset#root > .form-group.field.full_row, fieldset#root > .form-group.field-boolean {
    padding: 10px 20px;
}
.modal-body fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(odd) {
    padding: 10px 0px 10px 20px;
}
.modal-body fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(even) {
    padding: 10px 20px 10px 0px;
}
.modal-body .form-group.field.field-object p.help-block{
    position: relative;
    left: auto;
    margin-top: -1rem;
    bottom: auto;
}

/* modal mobile */

.modal-body{
  overflow-y: auto;
  max-height: 80vh;
}
@media (max-width: 768px){
  .modal-title.h4 {
      font-size: 1.2em;
  }
  .modal-content .modal-body fieldset#root > .form-group.field.full_row, fieldset#root > .form-group.field-boolean {
    padding: 10px;
  }
  .modal-content .modal-body fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(odd) {
      padding: 10px;
  }
  .modal-content .modal-body fieldset#root > .form-group.field.half_row:not(.field-object):nth-child(even) {
      padding: 10px;
  }
  .modal-content .modal-body .form-group.field.field-object p.help-block {
    margin-top: 0;
  }
  form.frmBeneficiario .form-group.field-submit.full_row {
      padding: 0 0 20px;
  }

}



.frmRegistro fieldset#root_origen_cliente .field{
  width: 100%;
}
.frmRegistro fieldset#root_origen_cliente{
  padding: 0;
  border-bottom-width: 0px;
}
.frmRegistro fieldset#root > .form-group.field-boolean {
  padding: 20px 50px;
}
.frmRegistro .form-button{
  text-align: center;
}