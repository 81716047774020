body{top:0 !important; width:100% !important;}
.top_header_block_wrap {
    display: block;
}
#navbarNav .dropdown-menu.show {
    z-index: 999999;
}
.alert.alert-danger {
    font-size: 85%;
    margin-bottom: 1rem;
    padding: 1rem;
}

.primary-color { color: #FA8819; }
a.onClick, .pointer {
    cursor: pointer;
}
.text-normal {
    text-transform: none;
}
.accent_btn.btn-primary{
  background-color: #FA8819 !important;
  width: auto;
  border-width: 0px;
}
/*-- header  --*/
.menu-menu-1 a.active {
    border-color: #FA8819;
    text-decoration: none;
}

.menu-menu-1 a:hover {
    width: 90% !important;
}

#navbarNav .user_info_wrap{ display:block;}

@media screen and (min-width: 992px) and (max-width: 1439px)  {
  .navbar-brand{ max-width: 150px;}
  .menu-menu-1 li {
      text-align: center;
  }
  .menu-menu-1 a {
      line-height: 1;
  }
}
@media (max-width: 767px){
  .dashboard_table_row{
        position: relative;
  }
  .dashboard_table_row>div.saldo_delete_item {
      padding: 0;
      border: 1px solid #ccc;
      width: 30px;
      position: absolute;
      bottom: 15px;
      right: 15px;
      margin: 0;
      text-align: center;
  }
  .dashboard_table_row>div.saldo_delete_item> img{
    max-width: 1em;
  }
}
@media (max-width: 576px){
  .top_header_block {
    display: none;margin: 0;padding: 0;
  }
  .dashboard_table_row>div {
    padding: 10px 10px 10px 0;
  }
  .dashboard_table_row>div.hidden-xs {
    display: none !important;
  }
  .dashboard_table_row {
    font-size: 13px;
  }
}

.user_info_wrap .user_name{margin-left: 10px;}

/*-- toast --*/
.toast{ color:#ffffff;}
.toast.success{ background-color: green;}
.toast.danger{ background-color: red;}

/*-- cookie-consent  --*/
#cookie-consent #cookie-consent-button{
    height:auto !important;
    font-size: .86em !important;
}
.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
}
.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}
.text-danger{
    color: darkred;
}
.alert-success{
    color: white;
    background-color: #5cb85c;
}
.pl-25{
    padding-left: 25px;
}
.pr-25{
    padding-right: 25px;
}

/** Footer **/
footer {
    background-color: #201F1E !important;
    padding: 60px 0 20px! important;
    color: white !important;
}

/*------------------------------------*/
.login_form button[type='submit'] {
    background-color: #CCCCCC;
    border:none;
    margin-top: 10px;
    width: 100%;
}
.login_form button[type='submit']:hover{
  background-color: #FA8819;
}
.login_form .forgot_pasword {
    font-size: 80%;
}
.login_form .input-group button{
  border:1px solid #BBB;
  box-shadow: none !important;
  font-size: inherit;
  height: auto;
  margin:6px 0 0;
  width: auto;
}
/*------------------------------------*/
.panel.panel-danger.errors * {
    font-size: 1rem !important;
}
.panel.panel-danger.errors .panel-title {
    color: #CCC;
    font-weight: 100;
}

.panel.panel-danger.errors .list-group-item.text-danger {
    background-color: rgba(220,53,69,0.1);
    border: none;
    color: darkred;
}
/*-- *dashboard --*/
.dashboard_tab_item.lastest_news_wrap >img {
    max-height: 300px;
}


/*-- *beneficiarios --*/
.react-tabs__tab-list {
    border-bottom: 2px solid #FA8819 !important;
}

.react-tabs__tab-list .react-tab{
  border-width: 0px !important;
  color: #CCC;
  box-sizing: border-box;
  display: inline-block;
  font-weight: 600;
  height: 40px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  cursor:pointer;
}
.react-tabs__tab-list .react-tab:hover{
  color:#3a3937 !important;
}
.react-tabs__tab-list .react-tab.react-tabs__tab--selected {
  color: #FA8819;
}


/* DATATABLE */
.rdt_TableHeader{ min-height: 0 !important; }
.rdt_TableHead .rdt_TableHeadRow {
    background-color: #000;
}
.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol{
  color:#FFFFFF;
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 600;
}
.color-default{
    color: #FA8819 !important;
}
.testimonials_item_comment{
    height: 440px !important;
}
.parrafo-cupones-digitales, parrafo-paqueteria{
    font-size: 1.2rem !important;
    margin-bottom: 30px !important;
    display: block !important;
    width: 100% !important;
    font-weight: 700 !important;
}
.box-recargas-telefonicas{
    background-color: #fff !important;
    cursor: pointer;
    border-radius: 10px !important;
}
.icon-user{
    font-size: 70px;
    color: #ff8000;
}
@media (min-width: 768px){
    .easysteps-title-text {
        font-size: 2rem;
        line-height: 2.8rem;
    }
}
.easysteps-title-text {
    display: block;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 2.4rem;
    color: #222;
}
.arrow-right{
    margin: auto;
    font-size: 40px;
    color: #ff8000;
}
@media screen and (max-width: 980px) {
    .arrow-right, .list-group-tipo-recarga{
        display: none;
    }
}
.descripcionPaqueteria .nav-link.active{
    color: #ff8000 !important;
}

.descripcionPaqueteria .nav-link{
    color: black !important;
}
.icon-color-default{
    color: #ff8000;
}
.box-custon-height{
    max-height:  120px;
    overflow-y: scroll;

}
.box-custon-height::-webkit-scrollbar {
    display: none;
}
.a-intercom{
    color: #ff8000 !important;
}
.backgroundBg{
    background-color: #f5f5f5 !important;
}
.intl-tel-input{
    width: 100% !important;
    margin-top: 6px !important;
}
.btn-color-default{
    background-color: #ff8000;
    color: white;
}
.hidden {
    display: none;
}

.progressbar {
    counter-reset: step;
}
.progressbar li {
    list-style-type: none;
    width: 50%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
}
.progressbar li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
}
.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: green;
}
.progressbar li.active:before {
    border-color: #55b776;
}
.progressbar li.active + li:after {
    background-color: #55b776;
}
.width80{
    width: 80px;
}
.height-30 {
    height: 30px;
}
.mt-6{
    margin-top: 6px;
}
.border-radius{
    border-radius: 100px;
}
.width20{
    width: 20%;
}

.btn-no-focus:focus {
    outline: none;
    box-shadow: none;
}

.btn-no-focus:hover{
    color: #ff8000;
}

.btn-redondo{
    width: 50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    background: #ff8000 !important;
    margin: auto !important;
    color: white !important;
    margin-top: -25px !important;
}
.fz-50{
    font-size: 50px;
}
.title-modal-paqueteria{
    font-size: 18px;
    font-weight: 700;
    margin-top: 8px;
}
.bg-size-cover{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    max-height: 500px;
}
@media (max-width: 767px) {
    .bg-size-cover{
        background-image: none !important;
        height: auto;
        padding: 2em;
        max-width: calc(100% - 1em);
    }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .width-30-respons{
        width: 20px !important;
        margin-top: -45px;
        margin-right: 4px
    ;
    }
    .text-18-respons{
        font-size: 0.8rem;
    }
}

.btn-outline-default {
    color: #ff8000;
    border-color: #ff8000;
}
.btn-outline-default:not(.active):hover {
    color: white;
    background-color: #ff8000;
}
.btn-outline-default.active {
    color: white;
    background-color: #ff8000;
}
.btn-custon-paqueteria{
    border: none;
    background: none;
    outline: none;
}
.btn-paqueteria:hover{
    color: #ff8000;
    border-radius: 2px;
}
.btn-paqueteria:focus {
    outline: none;
    box-shadow: none;
}
.border-color-default{
 border-color: #ff8000 !important;
}
.border-radius-default-5{
    border-radius: 5px;
}
:root {
    --lightgray: #efefef;
    --blue: steelblue;
    --white: #fff;
    --black: rgba(0, 0, 0, 0.8);
    --bounceEasing: cubic-bezier(0.51, 0.92, 0.24, 1.15);
}

.open-modal-paqueteria {
    font-weight: bold;
    background: var(--blue);
    color: var(--white);
    padding: .75rem 1.75rem;
    margin-bottom: 1rem;
    border-radius: 5px;
}

.modal-paqueteria {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    background-color: rgba(151,150,149,0.8);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
}

.modal-dialog-paqueteria {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(58,57,55,.2);
    border-radius: 0.3rem;
    outline: 0;
    max-width: 850px;
    max-height: 80vh;
    cursor: default;
}

.modal-dialog-paqueteria > * {
    padding: 1rem;
}

.modal-header-paqueteria,
.modal-footer-paqueteria {
    background: var(--lightgray);
}

.modal-header-paqueteria {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-header-paqueteria .modal-close-paqueteria {
    font-size: 1.5rem;
}

.modal-paqueteria p + p {
    margin-top: 1rem;
}

.modal-paqueteria {
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease-in;
}

.modal-paqueteria.is-visible {
    visibility: visible;
    opacity: 1;
}
.close-modal-paqueteria{
    border-radius: 50% !important;
    background: rgba(255, 128, 0, 0.5) !important;
    font-size: 29px;
    width: 6%;
    height: 45px;
    border: none;
    color: white;
    margin: auto;
    margin-top: -37px;
    font-weight: bold;
}
.fa-thumbs-o-up:before {
    font-size: 55px;
    color: #f80;
    border-radius: 16% !important;
    border: 1px solid #f80;
    padding: 5px;
}

.btn-paqueteria-departamento{
    border-radius: 20px;
    font-weight: bold;
}

.ProductSelector h1 {
    color: #333;
    font-family: 'Frank Ruhl Libre', 'PT Serif', serif;
    font-size: 20px;
    letter-spacing: normal;
    margin-bottom: 4px;
    font-weight: 500;
}
.ProductSelector .ListOfSkus {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    margin: 6px 0 12px 0;
}
.ProductSelector .ListOfSkus span.ProvidersSingleProduct--selected {
    outline: none;
    box-shadow: 0 0 0 3px #f8f8f8, 0 0 0 4px #979797;
    background-color: #CEC9C5;
    border: none;
    color: #333;
    font-weight: bold;
}

.ProductSelector .ListOfSkus>span {
    width: auto;
    min-width: 57px;
    height: 32px;
    max-width: none;
    flex: none;
    margin: 4px 10px 4px 0px;
    font-size: 13px;
    letter-spacing: .5px;
    color: #696969;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 5px;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
}
.ProductSelector h2 {
    color: #333;
    letter-spacing: 1px;
    margin: 12px 0 8px;
    line-height: 17px;
    font-weight: 500;
}
.ProductSelector h2 {
    display: block;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: .5px;
    margin: 0 0 10px;
    color: #333;
    text-transform: uppercase;
}
.MuiIconButton-colorSecondary{
    color: #ff8000 !important;
}
.card-custom{
    width: 145px;
}
.border-active-intercom {
    border: 1px solid #f80;
    box-shadow: 2px 2px 2px 1px rgb(255 136 0/90%)!important;
    background-color: #f80;
    color: white;
}
ol.pl-0 {
    list-style: none;
}
/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(rgba(20, 20, 20, .1), rgba(0, 0, 0, .1));
    background: -webkit-radial-gradient(rgba(20, 20, 20, .1), rgba(0, 0, 0, .1));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.z-index-1000{
    z-index: 999;
}
.title-form-pago-telefonico {
    background-color: #ff6625!important;
    color: #fff;
    padding: 4px;
    border-radius: 5px;
}
label.MuiFormControlLabel-root.checkbox-inline {
    width: 120px;
}
.top_header_block_contacts a{
    margin-left: 6px !important;
    margin-right: 6px;
}
.top_header_block_contacts img {
    margin-right: 0;
}

/*timeline*/

.timeline {
    border-left: 3px solid #FA8819;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 auto;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 40px;
    list-style: none;
    text-align: left;
    max-width: 45%;
}

@media (max-width: 767px) {
    .timeline {
        max-width: 98%;
        padding: 25px;
    }
}
.timeline .event {
    border-bottom: 1px dashed #e8ebf1;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

@media (max-width: 767px) {
    .timeline .event {
        padding-top: 30px;
    }
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline .event:before {
    left: -207px;
    top: 56px;
    content: attr(data-date);
    text-align: right;
    font-size: 0.9em;
    min-width: 120px;
    font-weight: 600;
}

@media (max-width: 767px) {
    .timeline .event:before {
        left: 0;
        text-align: left;
    }
}

.timeline .event:after {
    -webkit-box-shadow: 0 0 0 3px #FA8819;
    box-shadow: 0 0 0 3px #FA8819;
    left: -46.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 62px;
}

@media (max-width: 767px) {
    .timeline .event:after {
        left: -31.8px;
    }
}

div#cookie-consent {
    flex-flow: column !important;
}
.color-gris-default{
    color: #cccccc !important;
}

.btn-default-ful {
    color: #ffffff;
    background-color: #ff8000;
    border-color: #ff8000;
    padding-left: 40px;
    padding-right: 40px;
}
.text-pequeno{
    font-size: 14px;
}
.pl-34{
    padding-left: 34px;
}
.border-radius-10{
    border-radius: 10px;
}
.span-text-small{
    font-size: 10px;
}
.h-35{
    height: 35px !important;
}
.noClick {
   pointer-events: none;
}
.card-cesta-productos {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(81,88,94,.12);
    box-shadow: 0 0.05rem 0.2rem rgb(0 0 0 / 3%);
    border-radius: 0.35rem;
}
.table-detalle-compra{
    max-width: 360px;
    float: right;
    border: none;
}

.box-servicios {
    width: 100%;
    padding: 30px 30px 60px 30px;
    color: white;
    background:#2C2B29;
}

.box-servicios .color-title{
    color: white;
}

.btn-white{
    background-color: #ff8000 !important;
    color: white !important;
}
.box-servicios:hover{
    background-color: #ff8000 !important;
}
.box-servicios:hover a.btn{
    background-color: white !important;
    color:  #ff8000 !important;
}
.box-servicios a.btn{
  bottom: 30px;
  left: 50%;
  padding-right: 1em;
  position: absolute;
  transform: translateX(-50%);
}
.box-servicios a.btn:after{
    content: "\f105";
    font: normal normal normal 1em/1 FontAwesome;
    right: 0.2em;
    top: 0;
}

.box-light {
    width: 100%;
    padding: 30px 30px 30px 30px;
    color: #2C2B29; 
    background: white;
}

.box-light .color-title{
    color: #2C2B29;
}

.box-light:hover{
    box-shadow: 1px 1px 10px #cccccc;
}
.box-light:hover a.btn{
    background-color: white !important;
    color:  #ff8000 !important;
}
.box-light a.btn{
  bottom: 30px;
  left: 50%;
  padding-right: 1em;
  position: absolute;
  transform: translateX(-50%);
}
.box-light a.btn:after{
    content: "\f105";
    font: normal normal normal 1em/1 FontAwesome;
    right: 0.2em;
    top: 0;
}


.border-cesta-hover:hover{
    border: 2px solid #ff8000;
    padding-left: 5px;
}



/* banderas cupones digitales */
.box-banderas{
  flex-shrink: 0;
}
.box-banderas >img{
  height: 127px;
  margin-top: 1rem;
  object-fit: contain;
  width: 127px;
  z-index: 2;
}
.box-banderas >figcaption{
    background-color: white;
    flex-grow: 1;
    margin-left: -5rem;
    padding-left: 6rem;
    padding-top: 1rem;
    width: calc(100% - 127px);

}
.roundedicon-banderas {
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    height: 60px;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    width: 60px;
}
.text-tiltle{
    font-size: 38px;
    font-weight: bold;
    line-height : 35px !important;
}
.container-height-low{
    min-height: 60vh
}


/* footer block*/
.top_footer_section{
  background-color: #3a3937;
  color: #fff;
}

.top_footer_section .col-2.d-flex {
    flex: 0 0 100%;
}


.top_footer_section .col-wrapper{
  padding: 2rem 0;
  flex: 0 0 100%;
}
.top_footer_section .text-wrapper{
  border-right: 1px solid gray;
  padding: 0 1rem;
}
.top_footer_section .text-wrapper:last-child{
  border:none;
}
.top_footer_section .col-wrapper:hover{
  padding: 0;
}
.top_footer_section .col-wrapper:hover .text-wrapper{
  background-color: #FA8819;
  color: #fff;
  border:none;
  padding: 2rem 1rem 1rem;
  margin-top: -1rem;
}
.top_footer_section .title{
  font-family: 'Poppins';
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1rem;
}
.top_footer_section p{
  font-family: 'Poppins';
  font-size: 0.7rem;
  font-weight: 100;
  line-height: 1.1;
  margin-bottom: 0;
  text-transform: uppercase;
}

/*google translate Dropdown */

 #google_translate_element select{
   background:#FFFFFF;
   color:#000;
   border: none;
   border-radius:3px;
   padding:6px 8px
 }

 /*google translate link | logo */
 .goog-logo-link{
   display:none!important;
 }

 .goog-te-gadget{
 color:transparent!important;
 }

 /* google translate banner-frame */
 .goog-te-banner-frame{
 display:none !important;
 }

 #goog-gt-tt, .goog-te-balloon-frame{display: none !important;}
.goog-text-highlight { background: none !important; box-shadow: none !important;}

button#input-group-dropdown-1{
    border: 1px solid #BBB;
    border-radius: 0.25em 0 0 0.25em;
    height: 43.9px;
    margin-top: 0px;
    width: 75px;
}
.amount_to_receive_label span{
    bottom: 22px;
}
#amount_to_send{
  border-radius: 0 0.25em 0.25em 0;
}
select#send_city, input#amount_to_receive, input#outlined-adornment-amount{
    border-radius: 5px;
}

#google_translate_element div{
  text-align: center;
}
#google_translate_element select{
  width: auto;
}
.min-height-670{
    min-height: 670px;
}
.select-cantidad {
    min-width: 100px;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #FA8819;
}
/* navbarNav responsive punto de corte 992*/
@media (max-width: 992px){
  #navbarNav .user_info_wrap .user_info{
        justify-content: center;
  }
  #navbarNav .user_info_wrap .user_name .dropdown-menu {
      background: none;
      border: none;
      display: block;
      position: relative;
  }
  #navbarNav .user_info_wrap .user_name .dropdown-toggle{
    background: none;
  }

  .dashboard_wrapper .nav-pills {
      overflow-y: auto;
      flex-wrap: nowrap;
      justify-content: start;
  }
  .dashboard_wrapper .nav-pills .nav-link{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-size: .7em;
    padding: 0.5em;
    line-height: 1;
  }
  .dashboard_wrapper .nav-pills .nav-link span{
    display: block;
    text-align: center;
    opacity: .7;
    padding: 0px !important;
    margin-top:4px;
  }

}
/* cesta mobile */
@media (max-width: 412px){
  .dashboard_table_row .hidden-xs{
    display:none;
  }
  .dashboard_table_row {
    font-size: 12px;
  }
  .dashboard_table_row>div {
      padding: 8px 8px 8px 0;
  }
  .dashboard_table_row>div, .lastest_news_item {
      margin-bottom: 8px;
  }
}


/* SIDEBAR Dashboard */
.dashboard_wrapper .nav-pills .nav-link span.icon{
  background-repeat: no-repeat;
  background-position: left;
  padding-right: 50px;
  width: 30px;
  height: 30px;
}
.dashboard_wrapper .nav-pills .nav-link.active{
  color: #FA8819 !important;
}
.dashboard_wrapper .nav-pills .nav-link.active, .dashboard_wrapper .nav-pills .nav-link.active span{
  opacity: 1;
}

/* FOOTER */
footer .footer_nav a{
  color: #FFFFFF;
}
footer .footer_nav ul li a{
    color: #FA8819;
}

/* LANGUAGE */
select#changeLanguage {
  background-color: #000000;
  border: none;
  color:#CCCCCC;
  text-transform: uppercase;
}
/* whatsapp */
.btn-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #FFF !important;
    border-radius: 50px;
    text-align: center;
    font-size: 40px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    line-height: 1.45;
}
/* dashboard */
.dashboard_wrapper{
  height: auto !important;
}
.dashboard_wrapper .nav-pills{
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-box-align: start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}
@media screen and (max-width: 992px){
  .dashboard_wrapper .nav-pills{
    min-height: 80px;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
  }
  .dashboard_wrapper .tab-content{
    height: max-content !important;
  }
}
.border-color-default{
    border-color: #ff8000;
}
.border-left-none{
    border-top-left-radius: 0;
    border-left: none;
    border-bottom-left-radius: 0;
}

.border-right-none{
    border-top-right-radius: 0;
    border-right: none;
    border-bottom-right-radius: 0;
}

.toast.w-100{
  max-width: 100%;
}

.cart_menu{
  max-width: 2em;
  position: relative;
}

#cart_menu_num {
    align-items: center;
    border-radius: 50%;
    color: #ff8000;
    height: 20px;
    font-weight: 600;
    justify-content: center;
    position:absolute;
    right: -1rem;
    top: 0;
    width: 20px;
}

@media (max-width: 767px){
  .cart_menu{
    margin: 0 auto !important;
  }
}

.modal-header .result_header {
    width: 100%;
    margin: 0;
}

.btn-back{
    position:absolute;
}

@media (max-width: 767px){
    .btn-back {
        position: relative;
        margin: 0 auto 1em auto;
    }
}

.cesta_wrap .saldo_date_item img {
    object-fit: contain;
    width: 80px;
}

.table_row_merge{
    display: table !important;
    table-layout: fixed;
    width: 100%;

}


/* modal fix */
.modal.modal-dialog.modal-dialog-centered{
  position: fixed;
  max-width: 100vw;
  width: 100%;
  height: 100%;
  max-height: 100%;
  margin: 0;
}
/* .modal.modal-dialog.modal-dialog-centered .modal-dialog{
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
} */
.modal-header button.btn-close{
  display: inline-block;
  font: normal normal normal 1.5em/1 FontAwesome;
  background-color: transparent;
  border: none;
}
.modal-header button.btn-close:before{
  content: "\f00d";

}

.Timeline {
    display: flex;
    align-items: center;
    height: 200px;
}

.event1,
.event2, .event3 {
    position: relative;
}

.time {
    position: absolute;
    font-family: Arial, Helvetica, sans-serif;
    width: 50px;
    font-size: 8px;
    margin-top: -3px;
    margin-left: -5px;
    color: #9E9E9E;
}

.now{
    background-color: #004165;
    color: white;
    border-radius: 7px;
    margin: 5px;
    padding: 4px;
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    border: 2px solid white;
    font-weight: bold;
    box-shadow: 0 0 0 2px #004165
}
.eventTitle {
    font-family: "Arial Black", Gadget, sans-serif;
    color: #a71930;
    font-size: 11px;
    text-transform: uppercase;
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 12px;
    margin-top: -2px;
}
.event1Bubble:before {
    bottom: -10px;
    left: 13px;
    border-top-color: rgba(222, 222, 222, 0.66);
    border-width: 12px;
}

.event1Bubble:after {
    bottom: -8px;
    left: 13px;
    border-top-color: #F6F6F6;
    border-width: 12px;
}
.toast.error.show{
    background-color: #dc3545 !important;
    z-index: 1000;
}
.separador {
    border-bottom: 2px solid #FA8819;
    margin: 2em auto;
    text-align: center;
    width: 15vw;
}


/* nuevos colores */
.bg-fdcc8a{
    background-color: #fdcc8a;
}
.bg-58c5d7{
    background-color: #58c5d7;
}
.bg-1a406e{
    background-color: #1a406e;
}

.col-banner{
    margin-right: -1px;
    width: calc(100% / 5);
}
.bg-fdcc8a .col-banner{
    border-right: 1px solid #ebbc7f; 
}
.bg-58c5d7 .col-banner{
    border-right: 1px solid #1a406e; 
}
.col-banner:last-child{
    border: none !important;
    margin-right: 0;
    margin-bottom:0;
}
.box-light-btn{
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.75rem;
    font-weight: bold;
    font-size: 1.1em;
    letter-spacing: 1px;
}
@media (max-width: 767px) {
    .col-banner{
        border-right:none !important;
        margin-right:0;
        margin-bottom:1em;
        width: 100%;
    }
    .bg-fdcc8a .col-banner{
        border-bottom: 1px solid #ebbc7f; 
    }
    .bg-58c5d7 .col-banner{
        border-bottom: 1px solid #1a406e; 
    }
}


.banner-login{
    background-position: top left;
    background-repeat: no-repeat;
    margin: 0 auto;
    max-width: 100%;
    width: 570px;
    height: 676px;
    position: relative;
}
.banner-login > div{
    position: absolute;
    left: 1rem;
    width: 60%;
}
.banner-login > div:nth-child(1){
    top:40px;
}
.banner-login > div:nth-child(2){
    top: 280px;
}
.banner-login > div:nth-child(3){
    top: 510px;
    color:white;
}
.banner-login p {
    font-size: 11px;
    text-align: justify;
    font-weight: 600;
    width: 80%;
}
.content-imagen-servicios{
    height: 18rem;
    width: 100%;
    object-fit: cover;
}

.box-imagenes-servicios {
    position: relative;    /* para poder posicionar el texto de forma absoluta */
    display: inline-block; /* para que solo ocupe el ancho de la imagen */
}

.card-title-servicios {
    position: absolute;    /* posición absolute con respecto al padre */
    bottom: 0;             /* posicionada en la esquina inferior derecha */
    right: 1rem;
}

@media screen and (max-width: 575px){
    .beneficiaries_title_wrap {
        width: 100%;
        display: block;
        margin: 0 auto;
    }
}


.navsep {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #999999;
    margin-bottom: 29px;
    opacity: .4;
}


.btnsino:not(.active) {
    background-color: transparent;
    color:#000000;
}

.dashboard_tab_item .edit_btn{
    margin: 0 1rem 0 0;
}



.cursor-pointer{
    cursor: pointer;
}

[rol='button'].metodoSeleccionado > .box-banderas {
    border: 1px solid var(--color1) !important;
    box-shadow: 1px 1px 5px var(--color1);
}