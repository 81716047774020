
/*-- *STEPS --*/
.paqueteria_wrap .step_content_item,.paqueteria_wrap .footer-buttons {
    padding: 20px 0 0;
}
.payment_wrap ol.progtrckr {
    background-color: transparent;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0px;
    counter-reset: steps;
}
.payment_wrap ol.progtrckr >li {
    border:none;
    color: #3a3937;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 600;
    justify-content: center;
    line-height: 22px;
    padding-right: 100px;
    position: relative;
    width: calc(100% / 9);
}

.payment_wrap ol.progtrckr >li em{ display:none}
.payment_wrap ol.progtrckr >li span {
    font-size: 11px;
    line-height: 1;
    margin-bottom: 0;
    margin-top: 10px;
    padding:0;
    text-align: center;
    width: 100%;
}

.payment_wrap ol.progtrckr li:before {
    align-items: center;
    background-color: #fff;
    border: 2px solid #2ECC71;
    border-radius: 0px !important;
    bottom: 0;
    box-sizing: border-box;
    content: counter(steps);
    counter-increment: steps;
    display: flex;
    float: none;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
    justify-content: center;
    left: auto;
    line-height: 22px;
    width: 60px;
}
.payment_wrap ol.progtrckr li.progtrckr-done:before,.payment_wrap ol.progtrckr li.progtrckr-doing:before{
  background-color: #2ecc71;
  color: #fff;
  height: 60px;
  width: 60px;
}
.payment_wrap ol.progtrckr li.progtrckr-done:before {
    content: '✓';
}
.payment_wrap ol.progtrckr li.progtrckr-done:hover:before,.payment_wrap ol.progtrckr li.progtrckr-doing:hover:before{
  color:#FFFFFF;
}
.payment_wrap ol.progtrckr li::after {
    content: '';
    position: absolute;
    top: 33%;
    left: 85%;
    width: 56px;
    height: 6px;
    display: block;
}
.payment_wrap ol.progtrckr li:last-child::after {display:none}
.payment_wrap ol.progtrckr li.progtrckr-doing {
    color: inherit;
    border: none;
}
@media screen and (max-width: 1440px){
  .payment_wrap ol.progtrckr >li {
      align-items: flex-start;
      padding: 30px;
  }
  .payment_wrap .footer-buttons {
      padding: 30px;
  }
}
@media screen and (max-width: 768px){
  .recipient_select_wrap {
    flex-wrap: wrap;
    justify-content: center;
  }  
  main .payment_wrap ol.progtrckr >li {
      align-items: flex-start;
      padding: .5em;
  }
  main .payment_wrap .footer-buttons {
      padding: .5em;
  }
  main .payment_wrap ol.progtrckr li::after {
    display: none;
  }
  main .payment_wrap .footer-buttons {
      padding: 2em;
  }
  main .payment_wrap .footer-buttons .btn{
    margin: 0 0.5em;
    padding: 0.5em;
    width: auto;
  }
}


.payment_wrap .footer-buttons {
    display: flex;
    padding: 40px 135px 160px;
    background-color: #fff;
    justify-content: space-between;
}

.payment_wrap .footer-buttons .btn.btn-prev.btn-primary {
    background-color: #FFFFFF;
    border: 2px solid #FA8819;
    border-radius: 0px;
    box-sizing: border-box;
    color:#3a3937;
    cursor: pointer;
    display: inline-block;
    font-family: Poppins;
    font-weight: 100;
    height: 40px;
    line-height: normal;
    margin-left: auto;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    width: 160px;
    order:2;
}

.payment_wrap .footer-buttons .btn.btn-next.btn-primary.btn-lg.pull-left{
    background-color: transparent;
    border-color: #CCC;
    border-radius: 0px;
    border-width: 2px;
    cursor: pointer;
    color: #000;
    font-family: Poppins;
    transition: all .25s ease;
    text-transform: uppercase;
    padding: 0;
    width: 160px;
}
.payment_wrap .footer-buttons .btn.btn-prev.btn-primary:hover,.payment_wrap .footer-buttons .btn.btn-next.btn-primary.btn-lg.pull-left:hover {  color:#FA8819;}

.payment_wrap button.edit_btn {
    border: 0px;
    background: none;
}



.paqueteria_wrap label.label{
  font-size: 14px;
}
.paqueteria_wrap label > span{
  color:red;
}
.paqueteria_wrap input.form-control{
    border: 1px solid #BBB;
    border-radius: 0px;
    margin-top: 6px;
    height: 44px;
    padding: 0 20px;
}
.paqueteria_wrap h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.paqueteria_wrap .linea-dimenciones{
  margin-bottom: 1em;
}

.paqueteria_wrap .linea-dimenciones > div:last-child > button.btn {
  font-size: 1.5em;
  margin-top: 36px;
  height: 46px;
  width: 36px;
}

.paqueteria_wrap .linea-dimenciones > div:last-child > button.btn-sm.accent_btn {
  margin-top: 36px;
  margin-left: 5px;
  border-radius: 0px;
  height: 46px;
  font-size: .9em;
  padding: 0.25rem !important;
  width: auto;
}

@media screen and (max-width: 471px){
  .paqueteria_wrap .footer-buttons .btn.btn-next {
    order: 2;
  }
}
/*BANNERS-----*/

.paqueteria_wrap .banner-header{
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  height: 80px;
  margin-bottom: 10px;
  width: 100% !important;
}
.paqueteria_wrap .banner-header .title{
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 1rem 0.5rem;
}

@media screen and (max-width: 471px){
  .paqueteria_wrap .banner-header .title{
    width: 70%;
  }
}
/*FORM-----*/
.bg-ccc{
  background-color: #CCCCCC;
}
.w-input-form{
  background-color: #CCCCCC;
  margin: 0;
  position: relative;
  padding: 0;
}
.w-input-form label{
  margin-bottom: 0px;
  padding-top: 1em;
}
.w-input-form .input-form {
  margin-bottom: 5px;
  margin-left: 105px;
  width: calc(100% - 115px);
  padding-bottom: 10px;
}
.w-input-form .icon{  
  background-color: #3A3937;
  background-repeat: no-repeat;
  height: 96px; width: 96px;
  top: 0; left: 0;
  position: absolute;
  z-index: 2;
}
.btn-paqueteria, .btn-sino{
  border-radius: 0px;
}

